<template>
	<p
		v-if="hasFilters"
		class="filter-message"
	>
		<template v-if="animals.length">
			{{ randomMessage() }}! Es wurde{{ animals.length !==1 ? 'n' : '' }}
			<strong>{{ animals.length }}</strong>
			Tiere gefunden.
		</template>
		<template v-else>
			Nope, hier wurde nichts gefunden.
		</template>
		<br>
		<FilterButtonReset />
	</p>
</template>

<script>
import {messages} from '@/data/filter';
import {random} from '@schascha/brabbelback';
import {mapGetters} from 'vuex';


var randomMessage;


export default {
	computed: mapGetters(['animals', 'hasFilters']),
	methods: {
		randomMessage() {
			randomMessage = random(messages.filter(el => el !== randomMessage));
			return randomMessage;
		}
	}
};
</script>

<style lang="scss">
	.filter-message {
		.reset {
			margin: 1rem 0 0;
		}
	}
</style>
